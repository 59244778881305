
.sidenav {
    height: auto;
    width: 250px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    top: 45%;
    left: 0;
    margin: 0 20% 10% 0;
    background-color: #006a71;
    overflow: hidden;
    transition: 0.5s;
    padding-top: 5%;
    border-radius: 1px solid #cbeaed;
    position: fixed;
}

.sidenav a {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    padding: 5% 0 10% 8%;
    text-decoration: none;
    font-size: 0.9900rem;
    color: #cbeaed;
    display: block;
    transition: 0.3s;
    border-top: 1px solid #cbeaed;
    border-bottom: 1px solid #cbeaed;
}


.sidenav a:hover {
    color: #d3de32;

}

.sidenav  a:hover .fas {
    color: #d3de32;

}

.home span {
    margin-left: 10px;
}

.patients span {
    margin-left: 10px;
}

.open span {
    background-color: #006a71;
    color: #cbeaed;
}

.sidenav .close {
    position: relative;
    width: 250px;
}

.sidenav .closebtn {
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 36px;
    margin-left: 50px;
    color: #eeeeee;
    z-index: 999;
}

.close .closebtn:hover {
    color: #d3de32;
}

.home .is_active {
    color: #e32726;;
}

.patients .is_active {
    color: #e32726;;
}
