.authContainer{
    display:flex;
    flex-direction:column;
    text-align: center;
}
.authForm{
    display:flex;
    flex-direction:column;
    text-align: center;
    align-items:center;
    margin-top:30px;
}
.fieldContainer{
    display:flex;
    flex-direction:row;
}
.loginInputs{
    border:none;
    border-bottom:1px solid #9e9e9e;
    border-radius: 0px;
    margin: 0px 0px 20px 5px;
}
label{
    color:#ff0033;
}

.btn {
    cursor:pointer;
    width: 20%;
    height: 45px;
    background: -webkit-linear-gradient(top, rgba(227,38,38,1) 0%, rgba(114,20,19,1) 100%);
    color: #FFF;
    border: none;
    border-radius: 20px;
    margin: auto;
    display: block;
    top: 50px;
    position: relative;
    margin-bottom: 110px;
    overflow: hidden;
    transition: .5s ease;
}
.btn:hover{
    background: #FFF;
    color: #E32726;
    border: 1px solid #E32726;
    margin: auto;
    display: block;
    top: 50px;
    position: relative;
    margin-bottom: 110px;
    padding: 0px 55px;
}

.errormsg{
    /*color: #D8000C;
    background-color: #FFBABA;*/
    color:#ff0033;
    margin-bottom:50px;
}


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/


@media (min-width: 1281px) {
  
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
        
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
    .btn{
        width: 25%;
    }
    
  }  
  
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    .btn{
        width: 30%;
    }
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
    .btn{
        width: 40%;
    }
    .btn:hover{       
        padding: 0px;
    }
    .heading-text{
        font-size: 20px;
    }
    
  }