.header{
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content:space-between;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    box-shadow: 0px 1px #ddd;
    margin-right: 5%;
}

.final-buttons{
    margin-right: 15px;
    padding-right: 15px;
    font-size: 20px;
    cursor:pointer;
}

ul{
    list-style: none;
}

.logo-container img{
    width: 50px;
    margin: 5px 0 0 20px;
    cursor: pointer;
}
input{
    border-bottom: none;
    background: transparent;
    border: 1px solid #E32726;
    border-radius: 20px;
    padding: 0px 30px;
    height: 35px;
}

.searchIcon{
    background-color:transparent;
    border:none;
    color:#E32726;
    cursor:pointer;
    font-size: 20px;
}

.fa-sign-in-alt{
    font-size: 30px;
    color:#E32726 !important;
}

.searchContainer{
    display:flex;
    flex-direction:column;
}

.searchForm{
    margin: 5% 27% 0 45%;
}

.button-search {
    width: 100%
}

.errormsgSearch{
    color: #ff0033;
    margin-bottom: 0px;
    margin-top:5px;
    text-align: center;
}

.MuiButton-containedSecondary {
    background-color: #e32726 !important;
}



@media only screen and (max-width: 28em) {
    .logo-container img {
        width: 35px;
        margin: 0px;
        cursor: pointer;
        position: absolute;
        top: 8px;
    }

    .fullname{
        display: none;
    }

    .final-buttons {
        position: absolute;
        top: 12px;
        right: 0;
    }

    .searchForm{
        margin: 15% 19% 0px 19% !important;
    }

}

@media only screen and (min-width: 29em)  and (max-width: 48em) {
    .logo-container img {
        width: 35px;
        margin: 0px;
        cursor: pointer;
        position: absolute;
        top: 8px;
    }

    .fullname{
        display: none;
    }

    .final-buttons {
        position: absolute;
        top: 12px;
        right: 0;
    }

    .searchForm{
        margin: 5% 26% 0px 26% !important;
    }

}


@media only screen and (min-width: 48em) and (max-width: 64em){
    .searchForm{
        margin: 5% 27% 0 40%;
    }
    
}

