body{
    font-family: "Roboto";
}
.data{
    margin-top:20px;
    display:flex;
    flex-direction:row;
    align-items:stretch;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.dataTable{
    width:80%;
    padding:2px;
    margin-top:20px;
    border-spacing: 0;
}

.findForm{
    width:45%;
}

.tableHead{
    color:#000000;
    font-size: 18px;
}
.even{
   background-color:#fff;
}
.odd{
   background-color: rgba(242,242,242,0.5);
}
td{
    padding:10px;
    text-align: center;
}

th{
    padding:10px;
}
.buttonRed{
    background: #EB2416;
    color: white;
    border: 2px solid transparent;
    border-radius: 10px;
    margin-left: 10px;
    padding: 5px;
} 
.upload{
    background: #006a71;
    padding: 10px 35px;
    color: #fff;
    margin-top: 40px;
    border: 1px solid #E32726;
}

.lighter{
    font-weight: lighter !important;
}

.uploadContainer{
    display: flex;
    flex-direction:row;
    justify-content:center;
}
.uploadForm{
    display:flex;
    flex-direction:column;
    align-items:center;
}
.uploadInput{
    border: none;
    background: transparent;
    border-bottom: 1px solid #E32726;
    border-radius: 0px;
    height: 35px;
}

.uploadButton{
    background: transparent;
    padding: 10px 35px;
    color: #E32726;
    margin-top: 0px;
    border: 1px solid #E32726;
    margin-right: 0px;
    border-radius: 20px;
}

.deletePrevious{
    display: flex;
    align-items:center;
}

.gatoMotorLabel{
    display:flex;
    align-items: center;
    color:black;
}
.vipColor{
    color: #E32726 !important;
}
.textFormat{
    font-family: "Roboto" !important;
}
